// libs
import { css, useTheme } from 'styled-components';
import { useCallback } from 'react';

// components
import { List, ListItem } from './styled';
import { InternalLink } from 'components/Links/InternalLink';
import { OSIcon } from 'components';
import { CustomText } from 'typography/Text';
import { IconArrowRight } from '../styled';

// hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslations } from 'hooks/useTranslations';

// selectors
import { selectCurrentOrganisationId, selectModalData } from 'redux/selectors';

// actions
import { setModalContent } from 'redux/actions/app';

// constants
import { ROUTE_PATHS } from 'appConstants';

// types
import { IModalDeleteTags, ISystemSummaryModel, OSTypes } from 'types';

interface ISystemsTab {
  systems?: ISystemSummaryModel[];
  deletingMultiple: boolean;
}

export const SystemsTab = ({ systems, deletingMultiple }: ISystemsTab) => {
  const dispatch = useDispatch();

  const {
    palette: { fonts },
  } = useTheme();

  const onClose = useCallback(() => {
    dispatch(setModalContent({ type: null, data: null }));
  }, [dispatch]);

  const { translate } = useTranslations('tags');

  const orgId = useSelector(selectCurrentOrganisationId);
  const modalData = useSelector(selectModalData) as IModalDeleteTags['data'] | null;
  const tags = modalData?.records;

  return (
    <div>
      {systems && (
        <>
          <CustomText
            css={css`
              white-space: pre;
              display: block;
              margin-top: 1.5rem;
              margin-bottom: 0.75rem;
              > span {
                margin-left: 0.5rem;
              }
            `}
            type="body-2"
            color={fonts.bodyLight}
          >
            {translate(
              `modal.remove.tabs.systems.description.${
                deletingMultiple ? 'multiple_tags' : 'single_tag'
              }-${systems?.length > 1 ? 'multiple_items' : 'single_item'}`
            )}
          </CustomText>
          <List>
            {systems.map(system => {
              const systemName = system.description || system.hostname || system.systemId;

              return (
                <ListItem tabIndex={0} key={system.systemId}>
                  <OSIcon
                    platformType={system.platformType as OSTypes}
                    css={css`
                      display: inline-flex;
                      margin-right: 1rem;
                    `}
                  />
                  <InternalLink
                    title={systemName}
                    type="body-2"
                    to={`/org/${orgId}${ROUTE_PATHS.systems}/${system.systemId}`}
                    ellipsis={true}
                  >
                    {systemName}
                  </InternalLink>
                </ListItem>
              );
            })}
            {tags && (
              <InternalLink
                type="body-2"
                to={`/org/${orgId}${ROUTE_PATHS.systems}/?search=tags:${
                  tags.length > 1 ? '|' : ''
                }${tags.map(tag => tag.tag).join(',')}`}
                ellipsis={true}
                onClick={onClose}
                css={css`
                  margin-top: 1rem;
                `}
              >
                {translate('details.connections.tabs.systems.all_attached')} <IconArrowRight />
              </InternalLink>
            )}
          </List>
        </>
      )}
    </div>
  );
};
