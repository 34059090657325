// libs
import styled, { css } from 'styled-components';

// components
import { TabButtons } from 'components';
import { ContentContainer } from './styled';
import { DnsTab, KeysTab, PoliciesTab, SystemsTab } from './Tabs';

// hooks
import { useTranslations } from 'hooks/useTranslations';
import { useEffect, useState, useCallback } from 'react';

// types
import { ITabsNumber } from 'components/TabButtons/NumberTabPane';
import { IEnrolmentKey, IPolicy, IRecord, ISystemSummaryModel } from 'types';

export const TabsWrapper = styled.div`
  border-bottom: 0.0625rem solid ${({ theme }) => theme.palette.fills.strokeMedium};
  margin-bottom: 1rem;
`;

export type IConnectionsTabs = 'keys' | 'systems' | 'dns' | 'policies';

interface ITable {
  deletingMultiple: boolean;
  connections?: {
    keys?: IEnrolmentKey[];
    systems?: ISystemSummaryModel[];
    dnsRecords?: IRecord[];
    policies?: IPolicy[];
  };
  withEmptyState: boolean;
}

export const Table = ({ connections, withEmptyState, deletingMultiple, ...rest }: ITable) => {
  const { translate } = useTranslations('tags');

  const [tabs, setTabs] = useState<ITabsNumber[]>([]);
  const [selectedTab, setSelectedTab] = useState<IConnectionsTabs>('keys');

  const formTabs = useCallback(() => {
    const newTabs: ITabsNumber[] = [];
    if (connections) {
      const { keys, systems, dnsRecords, policies } = connections;

      if (keys?.length) {
        newTabs.push({
          type: 'number',
          key: 'keys',
          dataFormat: 'show',
          data: keys?.length,
          title: translate('details.connections.tabs.keys.name'),
          titleCss: css`
            width: 2rem;
          `,
        });
      }
      if (systems?.length) {
        newTabs.push({
          type: 'number',
          key: 'systems',
          dataFormat: 'show',
          data: systems?.length,
          title: translate('details.connections.tabs.systems.name'),
          titleCss: css`
            width: 3.8rem;
          `,
        });
      }
      if (dnsRecords?.length) {
        newTabs.push({
          type: 'number',
          key: 'dns',
          dataFormat: 'show',
          data: dnsRecords?.length,
          title: translate('details.connections.tabs.dnsRecords.name'),
          titleCss: css`
            width: 5.5rem;
          `,
        });
      }
      if (policies?.length) {
        newTabs.push({
          type: 'number',
          key: 'policies',
          dataFormat: 'show',
          data: policies?.length,
          title: translate('details.connections.tabs.policies.name'),
          titleCss: css`
            width: 3.2rem;
          `,
        });
      }

      setTabs(newTabs);
      setSelectedTab((newTabs[0]?.key as IConnectionsTabs) || 'keys');
    }
  }, [connections, setTabs, translate]);

  useEffect(() => {
    formTabs();
  }, [formTabs]);

  return (
    <ContentContainer {...rest}>
      <TabsWrapper>
        <TabButtons
          tabs={tabs}
          selectedKey={selectedTab}
          onChange={newKey => setSelectedTab(newKey as IConnectionsTabs)}
          tabCss={css`
            :not(:first-child) {
              margin-left: 1rem;
            }
          `}
        />
      </TabsWrapper>
      {selectedTab === 'keys' ? (
        <>
          {withEmptyState && connections?.keys?.length === 0 ? (
            <></>
          ) : (
            <KeysTab keys={connections?.keys} deletingMultiple={deletingMultiple} />
          )}
        </>
      ) : null}
      {selectedTab === 'systems' ? (
        <>
          {withEmptyState && connections?.systems?.length === 0 ? (
            <></>
          ) : (
            <SystemsTab systems={connections?.systems} deletingMultiple={deletingMultiple} />
          )}
        </>
      ) : null}
      {selectedTab === 'dns' ? (
        <>
          {withEmptyState && connections?.dnsRecords?.length === 0 ? (
            <></>
          ) : (
            <DnsTab dnsRecords={connections?.dnsRecords} deletingMultiple={deletingMultiple} />
          )}
        </>
      ) : null}
      {selectedTab === 'policies' ? (
        <>
          {withEmptyState && connections?.policies?.length === 0 ? (
            <></>
          ) : (
            <PoliciesTab policies={connections?.policies} deletingMultiple={deletingMultiple} />
          )}
        </>
      ) : null}
    </ContentContainer>
  );
};
